import React, { useState } from 'react'
import api from '../../api/api'
import styles from 'components/users/user-edit-form.module.scss'
import { PurpleButton } from 'components/shared/buttons/Button'

export default function UserEditForm(props) {
    const { user } = props
    const [name, setName] = useState(user.name || '')
    const [email, setEmail] = useState(user.email || '')
    //const [password, setPassword] = useState('')
    //const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [success, setSuccess] = useState([])
    const [errors, setErrors] = useState([])

    const handleSubmit = async ev => {
        ev.preventDefault()

        // Get the form element
        const formElement = document.getElementById(`edit_user_${user.id}`)
        const form = new FormData(formElement)

        if (!form.get('user[name]') || form.get('user[name]') === user?.name)
            form.delete('user[name]')
        if (!form.get('user[email]') || form.get('user[email]') === user?.email)
            form.delete('user[email]')

        //if (!form.get('user[password]')) form.delete('user[password]')
        //if (!form.get('user[password_confirmation]')) form.delete('user[password_confirmation]')

        const data = await api.updateUser(user.id, form)
        if (data.user) {
            //setName(data.user?.name)
            //setEmail(data.user?.email)
            //setPassword('')
            //setPasswordConfirmation('')
            setErrors([])
            setSuccess(['User updated.'])
            props.setUser(data.user)
        } else {
            setErrors(data.errors)
        }
    }

    const meta = document.querySelector('meta[name="csrf-token"]')
    //const csrfToken = meta && meta.getAttribute('content')

    return (
        <form className="edit_user" id={`edit_user_${user.id}`} acceptCharset="UTF-8" method="post">
            {errors?.length > 0 && (
                <div className={styles.error}>
                    <div>
                        {errors.map((msg, index) => (
                            <div key={index}>{msg}</div>
                        ))}
                    </div>
                </div>
            )}

            {success?.length > 0 && (
                <div className={styles.success}>
                    <div>
                        {success.map((msg, index) => (
                            <div key={index}>{msg}</div>
                        ))}
                    </div>
                </div>
            )}

            {/*<input type="hidden" name="authenticity_token" value={csrfToken || ''} />*/}
            <div className="field">
                <label className={styles.label} htmlFor="user_name">
                    Name
                </label>
                <input
                    className={styles.input}
                    type="text"
                    value={name}
                    name="name"
                    id="user_name"
                    onChange={e => setName(e.target.value)}
                />
            </div>
            <div className="field">
                <label className={styles.label} htmlFor="user_email">
                    Email
                </label>
                <input
                    className={styles.input}
                    type="text"
                    value={email}
                    name="email"
                    id="user_email"
                    onChange={e => setEmail(e.target.value)}
                />
            </div>

            <div className={styles.buttonContainer}>
                <PurpleButton
                    text={'Update'}
                    onClick={e => {
                        e.preventDefault()
                        handleSubmit(e)
                    }}
                />
            </div>
        </form>
    )
}
