import api from 'api/api'
import useStore from 'state/knovStore'

export const clearNotificationCount = () => {
    const { activeSpaceId } = useStore.getState()

    // because notification count is coupled to the filter system we have to do a pointless call
    // to getQuests to actually clear it
    return api
        .getQuests({
            notifications: true,
            space_id: activeSpaceId,
            clear: true,
        })
        .then(() => {
            // update the client side notification count
            useStore.getState().set({ numNoti: 0 })
        })
        .catch(error => {
            console.error("couldn't reset notification count", error)
        })
}
