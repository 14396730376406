import React, { useCallback, useState } from 'react'
import cn from 'classnames'
import styles from 'components/answers/answer-top-controls.module.scss'
import CopyLinkButton from 'components/shared/CopyLinkButton'
import { isMobile } from 'react-device-detect'
import perms from 'lib/perms'
import WithTooltip from 'components/shared/WithTooltip'
import api from 'api/api'
import usePanelContext from 'refactor/hooks/usePanelContext'
import StarQuestButton from 'components/quests/StarQuestButton'
import { ArchiveButton, ConfirmArchive } from 'components/answers/AnswerControls'

export default function AnswerTopControls(props) {
    const answer = props.answer
    const { hide } = usePanelContext()

    const onClick = ev => {
        ev.stopPropagation()
    }

    const visibleStyles = !hide && (props.isBsv || isMobile || props.hover) ? styles.visible : null

    const toggleControlsPopup = useCallback(ev => {
        ev.stopPropagation()
        props.toggleControlsPopup()
    }, [])

    const isBsv = props.isBsv

    const getTxUrl = answer => {
        const txId = answer.bsv_tx?.tx_id || answer.bsv_tx_id
        return txId ? `https://whatsonchain.com/tx/${txId}` : '#'
    }

    const getTxTooltip = answer => {
        const txId = answer.bsv_tx?.tx_id || answer.bsv_tx_id
        const status = !answer?.bsv_tx?.confirmed ? 'Unconfirmed' : 'Confirmed'
        return `${status} tx: ${txId || 'No Transaction ID'}`
    }

    const renderBsvTxStatus = answer => {
        const txId = answer.bsv_tx?.tx_id || answer.bsv_tx_id
        const bsvTx = answer.bsv_tx

        // check if we have a pending transaction that's more than 5 seconds old
        const isAwaitingResubmission =
            bsvTx && !txId && (new Date() - new Date(bsvTx.created_at)) / 1000 > 5

        // Show different status for old pending transactions
        if (isAwaitingResubmission) {
            const retryInfo = bsvTx.retries > 0 ? ` (${bsvTx.retries} retries)` : ''
            return (
                <WithTooltip
                    tip={`Treechat wasn't able to post this onchain immediately but will try again on the next block ${retryInfo}`}
                >
                    <div
                        className={cn(
                            styles.topControl,
                            styles.blockchainControl,
                            styles.notOnchain,
                        )}
                    >
                        <i className="fa fa-clock-o" />
                    </div>
                </WithTooltip>
            )
        }

        // Show spinner for new pending transactions
        if (!txId) {
            return (
                <div className={cn(styles.topControl)}>
                    <i className="fa fa-spinner fa-spin" />
                </div>
            )
        }

        const isUnconfirmed = !answer?.bsv_tx?.confirmed

        return (
            <WithTooltip tip={getTxTooltip(answer)}>
                <a
                    href={getTxUrl(answer)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={cn(
                        styles.topControl,
                        styles.blockchainControl,
                        isUnconfirmed && styles.notOnchain,
                    )}
                    onClick={ev => {
                        ev.stopPropagation()
                        // This should now be unnecessary since we check for txId above
                        if (!txId) {
                            ev.preventDefault()
                            api.postToBsv(answer.id)
                        }
                    }}
                >
                    <i className="fa fa-cube" />
                </a>
            </WithTooltip>
        )
    }

    return (
        <div className={cn(styles.answerTopControlsComp, visibleStyles)} onClick={onClick}>
            {!isBsv && gon.currentUser && perms.canEditAnswer(gon.currentUser, answer) && (
                <div className={cn(styles.topControlContainer)}>
                    <div
                        className={cn(styles.topControl, props.editMode && styles.editMode)}
                        onClick={props.toggleEdit}
                    >
                        <i className={`fa ${props.editMode ? 'fa-edit' : 'fa-edit'} fa-lg`} />
                    </div>
                </div>
            )}

            {isBsv && (
                <div className={cn(styles.topControlContainer)}>{renderBsvTxStatus(answer)}</div>
            )}

            <div className={cn(styles.topControlContainer)}>
                <div className={cn(styles.topControl, styles.copyLinkControl)}>
                    <CopyLinkButton
                        tooltip
                        getPublicLink={() => {
                            // props.getPublicLink() makes an api call to get the public link,
                            // but this needs to happen quickly to avoid being marked by the browser
                            // as not in response to a user interaction (copy/paste is only allowed
                            // in response to a user interaction).
                            // so we assemble it ourselves instead of making an api call
                            try {
                                const publicUrl = `${document.location.protocol}//${document.location.host}/quest/${props.answer.child_quests?.[0]?.id}`
                                return publicUrl
                            } catch (error) {
                                console.error("Couldn't generate public link!", error)
                                return null
                            }
                        }}
                        isMobile={isMobile}
                        styles={styles}
                        showLabel={false}
                    />
                </div>
            </div>

            <div className={cn(styles.topControlContainer)}>
                <WithTooltip tip="Add message to your starred stream.">
                    <div className={cn(styles.topControl)}>
                        <StarQuestButton
                            quest={answer.child_quests?.[0]}
                            contextStyles={styles.starQuestButton}
                        />
                    </div>
                </WithTooltip>
            </div>

            <div className={cn(styles.topControlContainer)}>
                <div className={cn(styles.topControl)} onClick={toggleControlsPopup}>
                    {props.showControlsPopup ? (
                        <i className="fa fa-remove fa-lg" />
                    ) : (
                        <i className="fa fa-ellipsis-v fa-lg" />
                    )}
                </div>
            </div>
        </div>
    )
}
