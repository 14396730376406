import React, { useRef, useEffect } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { ItemTypes } from './ItemTypes'
import Answer from 'components/answers/Answer'
import answerStyles from 'components/answers/Answer/answer.module.scss'
import {
    addEventListenerForAutoScroll,
    removeEventListenerForAutoScroll,
} from '../../lib/auto-drag'
import { isMobile } from 'react-device-detect'
import ThinAnswer from 'components/answers/ThinAnswer'
import useInsertRight from 'refactor/hooks/useInsertRight'

// Dragging on mobile ios doesn't work unless the elements are not able to be selected.
// But to drop the element it has to be able to be selected!!?!?!!?
// We have noselect class on the answerDnDContainer and then dynamically unset it when dropping,
// Then call this async to set noselect again for new drags.
const clearSelection = () => {
    //console.log('clear selection')
    if (window.getSelection) {
        window.getSelection().removeAllRanges()
    } else if (document.selection) {
        document.selection.empty()
    }
    document.querySelector('.no-select-answer-container').classList.add('noselect')
}

export default React.memo(
    props => {
        const ref = useRef(null)
        const previewRef = useRef(null)
        const answerRef = useRef(null)

        const disableContextMenu = event => {
            event.preventDefault()
            event.stopPropagation()
            return false
        }

        const [{ isDragging, addStyle }, drag, preview] = useDrag({
            item: {
                name: props?.answer?.id,
                type: ItemTypes.ANSWER,
                ix: props.ix,
                initial_ix: props.ix,
                answer: Object.assign({}, props.answer, {
                    recording_url: null,
                    answer_image: null,
                }),
                quest_id: props.quest.id,
                initial_quest_id: props.quest.id,
                quest_user_id: props?.quest?.user?.id,
                delete_local: props.deleteLocalItem,
                forbidDropOnQuest: props.forbidDrop,
            },

            previewOptions: {
                visibility: 'hidden',
            },

            canDrag: monitor => {
                //console.log('can drag start')
                //console.log('monitor', monitor, monitor.getItem(), props)
                //console.log('selection', window.getSelection().toString().length)
                const isDesktop = !isMobile
                const hasDesktopSelection = isDesktop && window.getSelection().toString().length > 0
                //console.log('has selection', hasSelection)
                if (
                    !hasDesktopSelection &&
                    gon.currentUser.id === props.quest.user_id &&
                    !window.isPanelScrolling &&
                    !answerRef?.current?.state?.editMode &&
                    !(isMobile && props.isTitle) &&
                    !props.isPublic
                ) {
                    //console.log('can drag true')
                    //if (window.getSelection) { window.getSelection().removeAllRanges(); }
                    if (isMobile)
                        document
                            .querySelector('.no-select-answer-container')
                            .classList.add('noselect')
                    if (window.navigator && window.navigator.vibrate) window.navigator.vibrate(30)
                    return true
                } else {
                    return false
                }
            },

            begin: monitor => {
                window.isDragging = true
                //window.addEventListener('contextmenu', disableContextMenu);
                addEventListenerForAutoScroll(ref.current)
                document.querySelector('.swiper-container').swiper.allowTouchMove = false
            },

            end: (item, monitor) => {
                //console.log('end start')
                window.isDragging = false
                document
                    .querySelectorAll('.scroll-container')
                    .forEach(el => (el.isScrolling = false))
                if (ref.current) {
                    removeEventListenerForAutoScroll(ref.current)
                }
                //window.removeEventListener('contextmenu', disableContextMenu);
                document.querySelector('.swiper-container').swiper.allowTouchMove = true
                if (isMobile) {
                    document
                        .querySelector('.no-select-answer-container')
                        .classList.remove('noselect')
                    setTimeout(clearSelection, 200)
                }

                if (
                    !monitor.didDrop() ||
                    (monitor.getDropResult() && monitor.getDropResult().error)
                ) {
                    props.resetAnswers()
                    // if (item.quest_id !== item.initial_quest_id)
                    props.resetOtherQuest(item.quest_id)
                    return
                }
                //console.log('end')
            },

            isDragging: monitor => monitor.getItem() && monitor.getItem().name == props?.answer?.id,

            collect: monitor => {
                return {
                    isDragging: monitor.isDragging(),
                }
            },
        })

        const [, drop] = useDrop({
            accept: ItemTypes.ANSWER,
            drop(item, monitor) {
                //console.log('drop')
                if (!ref.current) {
                    props.resetAnswers()
                    return { error: true }
                }

                const dragIndex = item.initial_ix

                if (item.ix === 0 && item.initial_quest_id != item.quest_id) {
                    // disallow dragging a title answer into another quest
                    props.resetAnswers()
                    return { error: true }
                }

                let hoverIndex
                if (isMobile) {
                    //hoverIndex = props.ix;
                    hoverIndex = item.ix
                } else {
                    hoverIndex = item.ix
                }

                // Disallow dragging an answer into it's own title slot
                if (props?.quest?.parent?.id == item?.answer?.id && item.initial_ix != 0) {
                    props.resetAnswers()
                    return { error: true }
                }

                if (
                    item.initial_quest_id === props.quest.id &&
                    gon.currentUser.id === props.quest.user_id
                ) {
                    if (dragIndex === hoverIndex) {
                        return
                    }
                    props.moveAnswer(dragIndex, hoverIndex)
                } else if (
                    gon.currentUser.id === props.quest.user_id &&
                    gon.currentUser.id === item.quest_user_id
                ) {
                    props.insertLocalItem(hoverIndex, item.answer)
                    item.delete_local(item.initial_ix)
                    props.moveAnswerBetweenQuests(hoverIndex, item.name)
                }
                //console.log('drop end')
            },

            hover: (item, monitor) => {
                //if (!isMobile) {
                if (true) {
                    const dragIndex = item.ix
                    const hoverIndex = props.ix
                    const clientOffset = monitor.getClientOffset()
                    const hoverBoundingRect = ref.current && ref.current.getBoundingClientRect()

                    if (hoverIndex === 0 && item.quest_id != item.initial_quest_id) {
                        return
                    }
                    if (hoverIndex === 0 && props?.quest?.id != item.quest_id) {
                        // disallow dragging a title answer into another quest
                        return
                    }

                    // Determine rectangle on screen
                    if (clientOffset && hoverBoundingRect) {
                        // Get vertical middle
                        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
                        // Determine mouse position

                        // Get pixels to the top
                        const hoverClientY = clientOffset.y - hoverBoundingRect.top
                        // Only perform the move when the mouse has crossed half of the items height
                        // When dragging downwards, only move when the cursor is below 50%
                        // When dragging upwards, only move when the cursor is above 50%

                        // Dragging downwards
                        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY / 2) {
                            return
                        }

                        // Dragging upwards
                        if (
                            dragIndex > hoverIndex &&
                            (hoverClientY > (hoverMiddleY * 3) / 2 || hoverBoundingRect.top < 0)
                        ) {
                            return
                        }
                        if (item.initial_quest_id == props.quest.id && item.forbidDropOnQuest)
                            return
                        if (
                            item.quest_id === props.quest.id &&
                            gon.currentUser.id === props.quest.user_id
                        ) {
                            if (dragIndex === hoverIndex) {
                                return
                            }
                            props.replaceItemsLocally(dragIndex, hoverIndex)
                            item.ix = hoverIndex
                        } else if (
                            gon.currentUser.id === props.quest.user_id &&
                            gon.currentUser.id === item.quest_user_id &&
                            (!props.quest.side_quest || item.answer.id !== props.quest.parent.id)
                        ) {
                            item.delete_local(item.ix)
                            props.insertLocalItem(hoverIndex, item.answer)
                            // props.insertLocalItem(hoverIndex, deletedItem)
                            item.ix = hoverIndex
                            item.quest_id = props.quest.id
                            item.delete_local = props.deleteLocalItem
                        }
                    }
                }
            },
        })

        useEffect(() => {
            preview(getEmptyImage(), { captureDraggingState: true })
        }, [])

        drag(drop(ref))

        let dragStyles
        if (isDragging) {
            dragStyles = {
                opacity: 0,
                maxHeight: '200px',
            }
        }

        const { toggleRightQuest } = useInsertRight()

        return (
            <div
                //className={cn(props?.titleAnswerProps?.questionHover && answerStyles.hover)}
                onMouseEnter={() => props?.titleAnswerProps?.onHeadEnter(true)}
                onMouseLeave={() => props?.titleAnswerProps?.onHeadLeave(false)}
            >
                {props.isTail ? (
                    <ThinAnswer
                        answer={props.answer}
                        onClick={() => toggleRightQuest(props.answer.quest_id)}
                        contextStyles={answerStyles.thinAnswer}
                    />
                ) : (
                    <div ref={ref} style={dragStyles} draggable={isMobile}>
                        <Answer
                            {...{
                                ...props,
                                // merge the titleProps in only if it's a title answer
                                ...(props.isTitle
                                    ? {
                                          ...props.titleAnswerProps,
                                          questionHover: props.titleAnswerProps.questionHover,
                                      }
                                    : {}),
                            }}
                            // This key is necessary to reset stale component state. TODO refactor this so state is not stale/unnecessary.
                            ref={answerRef}
                            previewRef={previewRef}
                        />
                    </div>
                )}
            </div>
        )
    },
    (prevProps, nextProps) => {
        return (
            nextProps.deleteLocalItem == prevProps.deleteLocalItem &&
            nextProps.ix == prevProps.ix &&
            nextProps?.answer?.updated_at == prevProps?.answer?.updated_at &&
            nextProps?.answer?.position == prevProps?.answer?.position &&
            !window.isDragging
        )
    },
)
