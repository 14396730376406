import React, { useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import cn from 'classnames'
import styles from './answer.module.scss'
import useStore from 'state/knovStore'
import WithTooltip from 'components/shared/WithTooltip'

const AnswerImage = React.forwardRef((props, ref) => {
    const [hasImageError, setHasImageError] = useState(false)

    const DEBUG = useStore(state => state.DEBUG)
    const answer = props.answer
    const draftAnswer = props.draftAnswer
    const isThin = props?.isThin || false
    let url

    if (hasBlob(draftAnswer)) {
        url = draftAnswer.answer_image_url
    } else if (hasBlob(answer)) {
        url = answer.answer_image_url
    } else {
        url = `${window.knovApiUrl}/api/v1/answers/${answer?.id}/image`
        const imageSize = props.imageSize

        let imageQueryString = `?updated_at=${parseInt(new Date(answer.updated_at).getTime())}`
        if (imageSize) {
            imageQueryString = imageQueryString
                ? `${imageQueryString}&size=${imageSize}`
                : `?size=${imageSize}`
        }
        if (hasImageError) {
            imageQueryString = imageQueryString
                ? `${imageQueryString}&break_cache=true`
                : '?break_cache=true'
        }
        url = `${url}${imageQueryString}`
    }

    const isActive = props.isActive
    const payloadContainerStyles = isMobile
        ? styles.mobilePayloadContainer
        : styles.payloadContainer

    const streamPayloadContainerStyles = isActive ? null : styles.streamPayloadContainer
    const downloadStyles = props.showDownload ? { display: 'flex' } : { display: 'none' }
    const embedImageStyles = props.isEmbed ? styles.embedImagePayload : null

    let name
    const path = props.path
    if (path) {
        const arr = path.split('/')
        name = arr[arr.length - 1]
    } else {
        name = 'knov'
    }

    // this will contain the corresponding BsvAttachment for this image if there is one
    const matchingBsvAttachment = answer?.bsv_attachments?.find(
        att => att.attachment_url == answer.answer_image_url,
    )

    const renderBsvAttachmentStatus = matchingBsvAttachment => {
        const bsvTx = matchingBsvAttachment?.bsv_tx
        const txId = bsvTx?.tx_id

        // Check if we have a pending transaction that's more than 5 seconds old
        const isAwaitingResubmission =
            bsvTx && !txId && (new Date() - new Date(bsvTx.created_at)) / 1000 > 5

        // Show different status for old pending transactions
        if (isAwaitingResubmission) {
            const retryInfo = bsvTx.retries > 0 ? ` (${bsvTx.retries} retries)` : ''
            return (
                <WithTooltip
                    tip={`Treechat wasn't able to post this onchain immediately but will try again on the next block ${retryInfo}`}
                >
                    <i className="fa fa-clock-o" />
                </WithTooltip>
            )
        }

        // Show spinner for new pending transactions
        if (!txId) {
            return (
                <WithTooltip tip="Transaction pending">
                    <i className="fa fa-spinner fa-spin" />
                </WithTooltip>
            )
        }

        const isUnconfirmed = !bsvTx?.confirmed

        return (
            <WithTooltip tip={`${isUnconfirmed ? 'Unconfirmed' : 'Confirmed'} tx: ${txId}`}>
                <a
                    href={`https://whatsonchain.com/tx/${txId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={ev => ev.stopPropagation()}
                >
                    <i className={`fa fa-cube`} />
                </a>
            </WithTooltip>
        )
    }

    return (
        <>
            {url && (
                <div
                    style={props.hasSeenGenerating ? { minHeight: 588 } : {}}
                    className={cn(
                        payloadContainerStyles,
                        styles.imagePayloadContainer,
                        streamPayloadContainerStyles,
                        embedImageStyles,
                        props.contextStyles,
                    )}
                >
                    <img
                        ref={ref}
                        onClick={ev => {
                            ev.preventDefault()
                            ev.stopPropagation()
                            props.toggle()
                        }}
                        src={url}
                        data-zoomable={props.uiId}
                        onError={e => {
                            //console.log('>>>>>>IMAGE ERROR', e)
                            setHasImageError(true)
                        }}
                    />

                    {props.showDownload && (
                        <div
                            style={downloadStyles}
                            className={styles.downloadLinkContainer}
                            onClick={ev => {
                                ev.stopPropagation()
                            }}
                        >
                            <a href={url} download={name}>
                                Download
                            </a>
                        </div>
                    )}
                    {matchingBsvAttachment && !isThin && (
                        <div className={styles.bsvStatusOverlay}>
                            {renderBsvAttachmentStatus(matchingBsvAttachment)}
                            {DEBUG && (
                                <pre style={{ width: '200px' }}>
                                    {JSON.stringify(matchingBsvAttachment, null, 2)}
                                </pre>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    )
})

export default AnswerImage

// We can use this to check if the answer's image was pasted in, in which case we can use the local link and not go to the server.
function hasBlob(answer) {
    return (
        answer?.answer_image_url?.startsWith('blob:') ||
        answer?.answer_image_url?.startsWith('data:')
    )
}
