import { useEffect, useRef } from 'react'

type Changes = {
    props: Record<string, { from: any; to: any }>
    contexts: Record<string, { from: any; to: any }>
}

export default function useLogRender(
    label: string,
    props: Record<string, any>,
    contexts: Record<string, any>,
): void {
    const prevPropsRef = useRef<Record<string, any> | null>(null)
    const prevContextsRef = useRef<Record<string, any> | null>(null)

    useEffect(() => {
        if (gon.env !== 'development') return

        const changes: Changes = {
            props: {},
            contexts: {},
        }

        if (prevPropsRef.current) {
            Object.entries(props).forEach(([key, value]) => {
                if (prevPropsRef.current![key] !== value) {
                    changes.props[key] = { from: prevPropsRef.current![key], to: value }
                }
            })
        }

        if (prevContextsRef.current) {
            Object.entries(contexts).forEach(([key, value]) => {
                if (prevContextsRef.current![key] !== value) {
                    changes.contexts[key] = { from: prevContextsRef.current![key], to: value }
                }
            })
        }

        if (Object.keys(changes.props).length > 0 || Object.keys(changes.contexts).length > 0) {
            console.log(`${label} re-rendered due to these changes:`, changes)
        }

        prevPropsRef.current = props
        prevContextsRef.current = contexts
    })
}
