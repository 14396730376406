import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import UserIcon from 'components/users/UserIcon'
import styles from './UserSelector.module.scss'
import cn from 'classnames'

export default function UserSelector({ user, selected = false }) {
    return (
        <div
            key={user?.id}
            className={cn(styles?.userSelectorComp, selected ? styles?.selected : {})}
        >
            <UserIcon user={user} size={'small'} />
            <div className={cn(styles?.username)}>{user?.value}</div>
        </div>
    )
}
