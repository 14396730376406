import React, { useCallback, useMemo } from 'react'
import cn from 'classnames'
import styles from './file-embed.module.scss'
// Import model-viewer web component
import '@google/model-viewer'

export default React.memo(function FileEmbed(props) {
    const { answerId, canDelete, file, showDelete, deleteFile: propsDeleteFile, removeFile } = props

    // Memoize the delete handler
    const deleteFile = useCallback(
        attachmentId => {
            if (attachmentId && answerId && canDelete) {
                propsDeleteFile(attachmentId, answerId)
            } else {
                removeFile()
            }
        },
        [answerId, canDelete, propsDeleteFile, removeFile],
    )

    // Memoize event handler
    const preventPropagation = useCallback(ev => {
        ev.stopPropagation()
        ev.preventDefault()
        return false
    }, [])

    // Memoize file type checks
    const fileTypes = useMemo(
        () => ({
            is3DModel: file.name?.match(/\.(glb|gltf|usdz)$/i),
            isAudio: file.name?.match(/\.(mp3|wav|ogg|m4a)$/i),
        }),
        [file.name],
    )

    return (
        <div
            className={cn(styles.fileEmbed)}
            onClick={preventPropagation}
            onMouseDown={preventPropagation}
            onMouseUp={preventPropagation}
            onTouchStart={preventPropagation}
            onTouchEnd={preventPropagation}
            onDrag={preventPropagation}
            onDragStart={preventPropagation}
        >
            {fileTypes.is3DModel ? (
                <div className={styles.modelViewer}>
                    <model-viewer
                        src={file.url}
                        alt={file.name}
                        disable-zoom
                        loading="lazy"
                        draco-decoder-path="https://www.gstatic.com/draco/v1/decoders/"
                        poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                        camera-controls
                        interaction-prompt-style="basic"
                        shadow-intensity="1"
                        camera-orbit="-30deg 75deg 105%"
                        max-camera-orbit="auto 180deg auto"
                        min-camera-orbit="auto 0deg auto"
                        camera-target="0 0 0"
                        style={{ width: '100%', maxHeight: '400px' }}
                    />

                    {props.showDelete && (
                        <div className={styles.delete}>
                            <i className="fa fa-close" onClick={() => deleteFile(file.id)} />
                        </div>
                    )}
                </div>
            ) : fileTypes.isAudio ? (
                <div
                    className={cn(styles.audioPlayer, 'pointer-events-none')}
                    onDrag={preventPropagation}
                    onDragStart={preventPropagation}
                    onDragOver={preventPropagation}
                    onDragEnter={preventPropagation}
                    onDragLeave={preventPropagation}
                    onDrop={preventPropagation}
                >
                    <audio
                        controls
                        onClick={preventPropagation}
                        onMouseDown={preventPropagation}
                        onMouseUp={preventPropagation}
                        onTouchStart={preventPropagation}
                        onTouchEnd={preventPropagation}
                        onDrag={preventPropagation}
                        onDragStart={preventPropagation}
                        onDragOver={preventPropagation}
                        onDragEnter={preventPropagation}
                        onDragLeave={preventPropagation}
                        onDrop={preventPropagation}
                        draggable="false"
                        style={{ pointerEvents: 'auto' }}
                    >
                        <source src={file.url} type={`audio/${file.name.split('.').pop()}`} />
                        Your browser does not support the audio element.
                    </audio>

                    {props.showDelete && (
                        <div className={styles.delete}>
                            <i className="fa fa-close" onClick={() => deleteFile(file.id)} />
                        </div>
                    )}
                </div>
            ) : (
                <div className={styles.downloadBtn}>
                    <div className={styles.icon}>
                        <i className="fa fa-paperclip" />
                    </div>

                    <a className={styles.name} href={file.url} download={true} target="_blank">
                        {file.name}
                    </a>

                    {props.showDelete && (
                        <div className={styles.delete}>
                            <i className="fa fa-close" onClick={() => deleteFile(file.id)} />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
})
