import React, { useState, useCallback } from 'react'
import styles from 'components/quests/thin-quest.module.scss'
import PrivateIcon from 'components/shared/icons/PrivateIcon'
import PublicIcon from 'components/shared/icons/PublicIcon'
import LinkIcon from 'components/shared/icons/LinkIcon'
import AllIcon from 'components/shared/icons/AllIcon'
import UserIcon from 'components/users/UserIcon'
import TeamIcon from 'components/users/TeamIcon'
import cn from 'classnames'
import useInsertRight from 'refactor/hooks/useInsertRight'
import ThinAnswer from 'components/answers/ThinAnswer'
import { isMobile } from 'react-device-detect'

export default function ThinQuest({
    quest,
    displayAnswerIds = [],
    contextStyles,
    showStream = false,
}) {
    const { toggleRightQuest } = useInsertRight()

    const onClick = useCallback(
        ev => {
            ev.preventDefault()
            ev.stopPropagation()

            if (quest) {
                toggleRightQuest(quest.id)
            } else {
                return
            }
        },
        [quest?.id],
    )

    const displayAnswers =
        displayAnswerIds.length > 0
            ? quest.sorted_answers.reduce((acc, answer) => {
                  if (displayAnswerIds.includes(answer.id)) {
                      acc.push({ answer })
                  } else {
                      const lastItem = acc[acc.length - 1]
                      if (lastItem && 'collapse' in lastItem) {
                          lastItem.collapse.answerCount++
                          lastItem.collapse.answers.push(answer)
                      } else {
                          acc.push({ collapse: { answerCount: 1, answers: [answer] } })
                      }
                  }
                  return acc
              }, [])
            : []

    return (
        <div className={cn(styles.thinQuestComp, contextStyles)} onClick={onClick}>
            <div className={cn(styles.parentContainer)}>
                {quest?.parent && (
                    <AnswerWrapper
                        answer={quest?.parent}
                        quest={quest}
                        showControls={true}
                        contextStyles={styles.thinAnswer}
                        showStream={showStream}
                    />
                )}

                {displayAnswers.length > 0 && <div className={styles.titleRootLine} />}
            </div>

            {displayAnswers.length > 0 && (
                <div className={styles.answersContainer}>
                    {displayAnswers.map((answerOrCollapse, index) => (
                        <div key={index} className={styles.answerContainer}>
                            {index !== displayAnswers.length - 1 && (
                                <div className={styles.rootLine} />
                            )}
                            <div className={cn(styles.branchLine)} />

                            {answerOrCollapse.answer && (
                                <AnswerWrapper
                                    answer={answerOrCollapse.answer}
                                    quest={quest}
                                    canHover={true}
                                    contextStyles={styles.thinAnswer}
                                    showControls={true}
                                />
                            )}

                            {answerOrCollapse.collapse && (
                                <div className={styles.collapseContainer}>
                                    {false && <div className={styles.collapseNode} />}

                                    <div className={styles.collapseLabel}>
                                        <span>
                                            {answerOrCollapse.collapse.answerCount} messages
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export function Stream({ team, user, filter }) {
    return (
        <>
            {team && (
                <div className={styles.streamIconComp}>
                    <TeamIcon
                        team={team}
                        contextStyles={cn(styles.streamIcon, styles.teamIcon)}
                        initialStyles={styles.initials}
                    />
                </div>
            )}

            {filter?.user && (
                <div className={styles.streamIconComp}>
                    <UserIcon user={user} contextStyles={styles.streamIcon} size={'tiny'} />
                </div>
            )}

            {filter?.private && (
                <div className={styles.streamIconComp}>
                    <PrivateIcon contextStyles={cn(styles.streamIcon, styles.privateIcon)} />
                </div>
            )}

            {filter?.link && (
                <div className={styles.streamIconComp}>
                    <LinkIcon contextStyles={cn(styles.streamIcon, styles.linkIcon)} />
                </div>
            )}

            {filter?.public && (
                <div className={styles.streamIconComp}>
                    <PublicIcon contextStyles={cn(styles.streamIcon, styles.publicIcon)} />
                </div>
            )}

            {filter?.all && (
                <div className={styles.streamIconComp}>
                    <AllIcon contextStyles={styles.streamIcon} />
                </div>
            )}
        </>
    )
}

function AnswerWrapper({
    answer,
    quest,
    contextStyles,
    canHover = false,
    showControls = false,
    showStream = false,
}) {
    const [isHovered, setIsHovered] = useState(false)
    const { toggleRightQuest } = useInsertRight()

    const onAnswerClick = useCallback(
        ev => {
            if (quest.parent_id === answer.id) {
                toggleRightQuest(quest.id)
            } else {
                toggleRightQuest(quest.id, answer.id)
            }
        },
        [quest?.id, answer?.id],
    )

    return (
        <div
            className={cn(styles.answerContentComp, canHover && isHovered && styles.hovered)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <ThinAnswer
                answer={answer}
                quest={quest}
                onClick={onAnswerClick}
                contextStyles={contextStyles}
                showControls={showControls}
                showStream={showStream}
            />
        </div>
    )
}
