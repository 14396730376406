import React, { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import questModel from 'components/quests/questModel'
import useQuestChannel from 'state/channels/useQuestChannel'
import usePanelContext from 'refactor/hooks/usePanelContext'
import cn from 'classnames'
import styles from 'components/panels/panel.module.scss'

const QuestInView = ({ children, questId }) => {
    const { scrollContainerRef } = usePanelContext()
    const scrollContainer = scrollContainerRef.current

    let minHeight = 0

    const [height, setHeight] = useState(minHeight)

    const { subscribe, unsubscribe } = useQuestChannel(questId)

    const { ref, inView, entry } = useInView({
        root: scrollContainer,
        rootMargin: '1000px 0px 1000px 0px',
        threshold: 0.1,
        triggerOnce: true,
        onChange: (inView, entry) => {
            if (inView) {
                subscribe()
            } else {
                if (entry.target.clientHeight > height) {
                    //console.log('setHeight?', entry.target.clientHeight, height)
                    setHeight(entry.target.clientHeight)
                }
                unsubscribe()
            }
        },
    })

    return (
        <div
            style={{
                minHeight: height,
            }}
            ref={ref}
            className={cn(styles.questInView, `quest-in-view-${questId}`)}
        >
            {inView ? children : null}
        </div>
    )
}

export default QuestInView
