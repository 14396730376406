import React, { useState, useEffect, useRef } from 'react'
import { getLinkPreview, ILinkPreviewInfo } from 'api/api'
import TweetEmbed from 'react-tweet-embed'
import styles from 'components/shared/linkpreview.module.scss'
import { uniq } from 'lodash'
import cn from 'classnames'
import { knovHostName } from 'state/knovStore'
import { useQuery } from '@tanstack/react-query'
import * as linkify from 'linkifyjs'
import ErrorBoundary from 'components/shared/ErrorBoundary'
import usePanelContext from 'refactor/hooks/usePanelContext'
import TwitterUrl from 'components/urls/TwitterUrl'
import { isMobile } from 'react-device-detect'

export const knovLinkRegexp = new RegExp(
    `^https?:\/\/(([a-zA-Z0-9-]+\.))?(${document.location.host}|${knovHostName}|knovigator.com|treechat.ai)(:[0-9]+)?\/quest\/`,
)

export const twitterRegexp = /^https?:\/\/(www\.)?(x|twitter)\.com\/[^\/]+\/status\/([0-9]+)/

export const LinkPreview = React.memo(function ({
    url,
    domain,
    inViewCallback,
    urlModel = null,
    onMouseEnterAnswer,
    onMouseLeaveAnswer,
    onHeadEnter,
    onHeadLeave,
}: {
    url: string
    domain: string
    inViewCallback?: () => void
    urlModel?: any
    onMouseEnterAnswer?: (ev: React.MouseEvent) => void
    onMouseLeaveAnswer?: (ev: React.MouseEvent) => void
    onHeadEnter?: (ev: React.MouseEvent) => void
    onHeadLeave?: (ev: React.MouseEvent) => void
}) {
    const { hide } = usePanelContext()
    const linkRef = useRef(null)
    const hiddenEls = useRef(null)

    useEffect(
        function onAnimationHide() {
            if (linkRef.current && hide && hiddenEls.current === null) {
                hiddenEls.current = linkRef.current.querySelectorAll('.twitter-tweet iframe')
                hiddenEls.current?.forEach(element => {
                    element.style.visibility = 'hidden'
                })
            } else {
                hiddenEls.current?.forEach(element => {
                    element.style.visibility = 'visible'
                })
                hiddenEls.current = null
            }
        },
        [hide],
    )

    if (twitterRegexp.test(url)) {
        const [, , , id] = url.match(twitterRegexp)
        return (
            <ErrorBoundary label="tweetLinkPreview">
                <div ref={linkRef}>
                    {urlModel && urlModel.service_name == 'twitter' ? (
                        <TwitterUrl url={urlModel} />
                    ) : (
                        id && (
                            <TweetEmbed
                                id={id}
                                onTweetLoadSuccess={() => {
                                    if (inViewCallback) inViewCallback()
                                }}
                            />
                        )
                    )}
                </div>
            </ErrorBoundary>
        )
    }

    return (
        <ErrorBoundary label="linkPreview">
            <OpenGraphLinkPreview
                url={url}
                domain={domain}
                onMouseEnterAnswer={onMouseEnterAnswer}
                onMouseLeaveAnswer={onMouseLeaveAnswer}
                onHeadEnter={onHeadEnter}
                onHeadLeave={onHeadLeave}
            />
        </ErrorBoundary>
    )
})

function isValidUrl(url) {
    try {
        new URL(url).hostname
        return true
    } catch {}
    return false
}

function extractHostFromUrl(url) {
    try {
        const hostname = new URL(url).hostname
        return hostname.replace(/^(www\.)?/, '')
    } catch (e) {
        return url
    }
}

interface ILinkPreviewDisplayProps extends ILinkPreviewInfo {
    url: string
    domain: string
    compact?: boolean
    onMouseEnterAnswer?: (ev: React.MouseEvent) => void
    onMouseLeaveAnswer?: (ev: React.MouseEvent) => void
    onHeadEnter?: (ev: React.MouseEvent) => void
    onHeadLeave?: (ev: React.MouseEvent) => void
}

function OpenGraphLinkPreview({
    url,
    domain,
    onMouseEnterAnswer,
    onMouseLeaveAnswer,
    onHeadEnter,
    onHeadLeave,
    compact = false, // TODO: implement a compact mode
}: ILinkPreviewDisplayProps) {
    if (!url || !isValidUrl(url)) return <></>

    const [hover, setHover] = useState(false)

    const onMouseEnter = ev => {
        if (isMobile) return false
        // Here we do the opposite entry function bc entering this component is equivalent to leaving the parent comp.
        setHover(true)
        if (onMouseLeaveAnswer) onMouseLeaveAnswer(ev)
        if (onHeadLeave) onHeadLeave(ev)
    }

    const onMouseLeave = ev => {
        if (isMobile) return false
        // Here we do the opposite entry function bc entering this component is equivalent to leaving the parent comp.
        setHover(false)
        if (onMouseEnterAnswer) onMouseEnterAnswer(ev)
        if (onHeadEnter) onHeadEnter(ev)
    }

    const linkPreviewQuery = useQuery({
        queryKey: [gon.api.linkpreview, url],
        queryFn: () => getLinkPreview(url),
        staleTime: 6 * 60 * 60 * 1000,
        gcTime: 6 * 60 * 60 * 1000,
    })
    const loading = linkPreviewQuery.isLoading
    const { title, description, image } = linkPreviewQuery?.data || {}

    const handleClick = (ev: React.MouseEvent) => {
        ev.stopPropagation()
    }

    return (
        <div
            className={cn(styles.linkPreviewDisplay, hover && styles.hover)}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <a href={url} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
                {loading ? (
                    <div className={cn(styles.loading)}>
                        <i className="fa fa-circle-o-notch fa-spin" />{' '}
                    </div>
                ) : image && isValidUrl(image) ? (
                    <div className={cn(styles.image)}>
                        {/** NOTE: using an <object> instead of an image here so that we can show the broken image correctly
                         * see: https://stackoverflow.com/questions/22051573/how-to-hide-image-broken-icon-using-only-css-html*/}
                        <object data={image} type="image/jpeg">
                            <i className="fa fa-globe" />
                        </object>
                    </div>
                ) : (
                    <></>
                )}

                <div className={cn(styles.title)}>
                    <div className={cn(styles.favicon)}>
                        <img
                            src={`https://icon.horse/icon/${domain}?size=small`}
                            alt={domain}
                            onError={e => {
                                e.currentTarget.style.display = 'none'
                                e.currentTarget.parentElement.innerHTML =
                                    '<i class="fa fa-globe"></i>'
                            }}
                        />
                    </div>
                    <div className={cn(styles.titleText)}>{title ? title : url}</div>
                </div>
                <div className={cn(styles.description)}>{description}</div>
                <div className={cn(styles.urlhost)}>{extractHostFromUrl(url)}</div>
            </a>
        </div>
    )
}

/** returns an object of shape {knovEmbedLinks: [], nonKnovLinks: []} */
export function extractUrls(text) {
    const urls = {
        knovEmbedLinks: [],
        nonKnovLinks: [],
    }

    if (text)
        return linkify
            ?.find(text)
            ?.filter(({ href }) => href.startsWith('http'))
            ?.map(u => u?.href)
            ?.reduce(({ knovEmbedLinks, nonKnovLinks }, url) => {
                if (url?.match(knovLinkRegexp)) knovEmbedLinks = uniq([...knovEmbedLinks, url])
                else nonKnovLinks = uniq([...nonKnovLinks, url])
                return { knovEmbedLinks, nonKnovLinks }
            }, urls)
    else return urls
}
