import React from 'react'
import cn from 'classnames'
import TeamIcon from 'components/users/TeamIcon'
import PublicIcon from 'components/shared/icons/PublicIcon'
import PrivateIcon from 'components/shared/icons/PrivateIcon'
import LinkIcon from 'components/shared/icons/LinkIcon'
import HodlockerIcon from 'components/shared/icons/HodlockerIcon'
import TreechatIcon from 'components/shared/icons/TreechatIconPng'
import TwetchIcon from 'components/shared/icons/TwetchIcon'
import questModel from 'components/quests/questModel'
import styles from 'components/quests/stream-icon.module.scss'

export default function StreamIcon({ quest, isRoot, contextStyles }) {
    const isTreechat = questModel.isPublic(quest)
    const isHodlocker = gon.HODLOCKER_TEAM_ID && gon.HODLOCKER_TEAM_ID === quest.team_id
    const isTwetch = gon.TWETCH_TEAM_ID && gon.TWETCH_TEAM_ID === quest.team_id
    const isPublic = isHodlocker || isTreechat || isTwetch

    const showTreechat = !isRoot && isTreechat
    const showHodlocker = !isRoot && isHodlocker
    const showTwetch = !isRoot && isTwetch
    const showPublic = isRoot && isPublic
    const showTeam = questModel.hasTeam(quest) && !isPublic

    return (
        <div className={contextStyles}>
            {questModel.isPrivate(quest) && (
                <PrivateIcon
                    contextStyles={cn(styles.permsIcon, styles.privateIcon, contextStyles)}
                />
            )}

            {showTreechat && (
                <TreechatIcon
                    contextStyles={cn(styles.permsIcon, styles.treechatIcon, contextStyles)}
                />
            )}

            {showHodlocker && (
                <HodlockerIcon
                    contextStyles={cn(styles.permsIcon, styles.hodlockerIcon, contextStyles)}
                />
            )}

            {showTwetch && (
                <TwetchIcon
                    contextStyles={cn(styles.permsIcon, styles.twetchIcon, contextStyles)}
                />
            )}

            {showPublic && (
                <PublicIcon
                    contextStyles={cn(styles.permsIcon, styles.publicIcon, contextStyles)}
                />
            )}

            {questModel.isLinkShare(quest) && (
                <LinkIcon contextStyles={cn(styles.permsIcon, styles.linkIcon, contextStyles)} />
            )}

            {showTeam && (
                <TeamIcon
                    team={quest.team}
                    contextStyles={cn(styles.permsIcon, styles.initialStyles, contextStyles)}
                    quest={quest}
                />
            )}
        </div>
    )
}
