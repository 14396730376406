import React, {
    useRef,
    useState,
    useLayoutEffect,
    useCallback,
    ReactNode,
    useEffect,
    useMemo,
} from 'react'
import { PanelContextProvider } from 'refactor/hooks/usePanelContext'
import { isMobile } from 'react-device-detect'
import cn from 'classnames'
import styles from 'components/panels/panel-container.module.scss'
import { getCenterIndex } from 'state/imperativeApis/swiperApi'
import { SMALL, BIG, transformTo, getTransform } from 'lib/transform'
import { debounce } from 'lodash'
import useStore from 'state/knovStore'
import { PanelState } from 'state/PanelState'
import { Filter } from 'components/filters/useStreamFilters'

declare global {
    interface Window {
        isPanelScrolling: ReturnType<typeof setTimeout> | null
    }
}

type ScrollDirection = 'up' | 'down' | null

interface PanelContainerProps {
    panel: PanelState
    index: number
    hide?: boolean
    children: ReactNode
    isEmpty?: boolean
}

export default React.memo(function PanelContainer({
    panel,
    index,
    hide,
    children,
    isEmpty,
}: PanelContainerProps) {
    // Only subscribe to panel changes for this specific panel
    const panelCount = useStore(state => state.panels.state.length)
    const panelContainerRef = useRef<HTMLDivElement>(null)
    const scrollContainerRef = useRef<HTMLDivElement>(null)
    const [isVisible, setIsVisible] = useState(true)
    const [showScrollButton, setShowScrollButton] = useState<ScrollDirection>(null)
    const [externalHideScrollButton, setExternalHideScrollButton] = useState<boolean>(false)
    const showScrollButtonRef = useRef<ScrollDirection>(null)
    const lastScrollTop = useRef(0)
    const scrollingTimeoutRef = useRef<number | null>(null)
    const hideScrollButtonTimeoutRef = useRef<number | null>(null)

    useEffect(() => {
        showScrollButtonRef.current = showScrollButton
    }, [showScrollButton])

    const defaultDelay = 2000
    const delayedHideScrollButton = useCallback((delay: number = defaultDelay) => {
        if (hideScrollButtonTimeoutRef.current) {
            clearTimeout(hideScrollButtonTimeoutRef.current)
        }
        hideScrollButtonTimeoutRef.current = window.setTimeout(
            () => setShowScrollButton(null),
            delay,
        )
    }, [])

    const setScrollButton = useMemo(
        () =>
            debounce(
                () => {
                    const scrollContainer = scrollContainerRef.current
                    if (!scrollContainer) return

                    // Batch all reads
                    const reads = () => {
                        const offset = 0
                        const scrollTop = scrollContainer.scrollTop
                        const scrollHeight = scrollContainer.scrollHeight
                        const clientHeight = scrollContainer.clientHeight

                        return {
                            atTop: scrollTop <= offset,
                            atBottom: scrollTop >= scrollHeight - clientHeight - offset,
                            scrollTop,
                            hasFilter: !!(
                                panel.filter?.questId ||
                                panel.filter?.history ||
                                panel.filter?.starred
                            ),
                            lastScrollTopValue: lastScrollTop.current,
                            currentDirection: showScrollButtonRef.current,
                        }
                    }

                    // Batch all writes
                    const writes = measurements => {
                        const {
                            atTop,
                            atBottom,
                            scrollTop,
                            hasFilter,
                            lastScrollTopValue,
                            currentDirection,
                        } = measurements
                        const atEdge = atTop || atBottom

                        delayedHideScrollButton(atEdge ? 0 : defaultDelay)

                        if (hasFilter) {
                            const scrollDirection = scrollTop > lastScrollTopValue ? 'down' : 'up'
                            setShowScrollButton(scrollDirection)
                            lastScrollTop.current = scrollTop
                        } else {
                            if (currentDirection !== 'down') {
                                setShowScrollButton('up')
                            } else {
                                setShowScrollButton(null)
                            }
                        }
                    }

                    // Schedule reads then writes in next frame
                    requestAnimationFrame(() => {
                        const measurements = reads()
                        writes(measurements)
                    })
                },
                50,
                { leading: true, maxWait: 100 },
            ),
        [],
    )

    const onPanelScroll = useCallback(
        (ev: React.UIEvent<HTMLDivElement>) => {
            if (scrollingTimeoutRef.current) {
                window.cancelAnimationFrame(scrollingTimeoutRef.current)
            }

            scrollingTimeoutRef.current = window.requestAnimationFrame(() => {
                setScrollButton()
                scrollingTimeoutRef.current = null
            })
        },
        [setScrollButton],
    )

    useLayoutEffect(
        function performTransform() {
            if (isMobile || !panelContainerRef.current) return

            const transformContainer =
                panelContainerRef.current?.querySelector<HTMLElement>('.transform-container')
            const scrollContainer =
                panelContainerRef.current?.querySelector<HTMLDivElement>('.scroll-container')
            if (!transformContainer || !scrollContainer) return

            // Batch all reads
            const reads = () => {
                const centerIndex = getCenterIndex()
                const transformObject = getTransform(transformContainer.style.transform)
                const currentScale = transformObject.scale || 1
                return { centerIndex, currentScale }
            }

            // Batch all writes
            const writes = ({ centerIndex, currentScale }) => {
                if (centerIndex === index && currentScale === SMALL) {
                    transformTo(BIG, panel)
                } else if (centerIndex !== index && currentScale === BIG) {
                    transformTo(SMALL, panel)
                }
            }

            // Schedule reads then writes
            const frame = requestAnimationFrame(() => {
                const measurements = reads()
                writes(measurements)
            })

            return () => cancelAnimationFrame(frame)
        },
        [index],
    )

    const handleScrollButtonClick = useCallback(() => {
        if (scrollContainerRef.current) {
            if (showScrollButton === 'up') {
                scrollContainerRef.current.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                })
            } else if (showScrollButton === 'down') {
                scrollContainerRef.current.scrollTo({
                    top: scrollContainerRef.current.scrollHeight,
                    behavior: 'smooth',
                })
            }
        }
    }, [showScrollButton])

    // We  memoize the panel context so it does not cause re-renders when passed to the provider below.
    const panelContext = React.useMemo(
        () => ({
            panel,
            panelId: panel.panelId,
            filter: panel.filter,
            hide,
            panelContainerRef,
            scrollContainerRef,
            setExternalHideScrollButton,
        }),
        [panel.panelId, panel.filter, hide],
    )

    useEffect(() => {
        if (!panelContainerRef.current || isMobile) return

        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    setIsVisible(entry.isIntersecting)
                })
            },
            {
                root: null, // Use viewport
                rootMargin: '0px 100% 0px 100%', // 100% horizontal margin to match panel width, 200px vertical margin for early loading
                threshold: 0.1, // Show when at least 10% is visible
            },
        )

        observer.observe(panelContainerRef.current)

        return () => {
            if (panelContainerRef.current) {
                observer.unobserve(panelContainerRef.current)
            }
            observer.disconnect()
        }
    }, [])

    useEffect(() => {
        return () => {
            if (scrollingTimeoutRef.current) {
                window.cancelAnimationFrame(scrollingTimeoutRef.current)
            }
            if (hideScrollButtonTimeoutRef.current) {
                clearTimeout(hideScrollButtonTimeoutRef.current)
            }
        }
    }, [])

    return (
        <PanelContextProvider value={panelContext}>
            <div
                style={{
                    width: '100%',
                    visibility: isVisible ? 'visible' : 'hidden',
                }}
                className={cn(
                    isMobile && 'is-mobile',
                    styles.panelContainer,
                    'panel-container-comp',
                )}
                ref={useCallback(ref => {
                    if (!ref) {
                        return
                    }

                    panelContainerRef.current = ref
                    const panelRef = panel.getPanelRef()
                    if (panelRef !== ref) {
                        panel.setPanelRef(ref)
                        const prevScrollTop = panelRef?.scrollTop
                        if (prevScrollTop) {
                            ref.scrollTop = prevScrollTop
                        }
                    }
                }, [])}
            >
                <div
                    ref={scrollContainerRef}
                    className={cn('scroll-container', styles.scrollContainer, 'h100')}
                    onScroll={onPanelScroll}
                    style={{
                        display: isVisible ? 'block' : 'none',
                    }}
                >
                    <div
                        className={cn(
                            styles.transformContainer,
                            `panel-${panel.panelId}`,
                            'transform-container h100',
                            isMobile && 'is-mobile',
                            'will-change-transform',
                        )}
                    >
                        {children}
                    </div>
                </div>

                {!externalHideScrollButton && (
                    <div
                        className={cn(
                            styles.scrollButtonContainer,
                            !showScrollButton && styles.hidden,
                        )}
                    >
                        <div
                            className={cn(
                                styles.scrollButton,
                                isMobile && styles.mobileScrollButton,
                                !isMobile &&
                                    getCenterIndex() === index &&
                                    styles.centerScrollButton,
                            )}
                            onClick={handleScrollButtonClick}
                        >
                            <div className={styles.scrollButtonFace}>
                                <i
                                    className={`fa fa-chevron-${
                                        showScrollButton === 'up'
                                            ? 'up'
                                            : showScrollButton === 'down'
                                            ? 'down'
                                            : ''
                                    }`}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </PanelContextProvider>
    )
})
