import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import useStore from 'state/knovStore'
import { isMobile } from 'react-device-detect'
import { getCenterIndex } from 'state/imperativeApis/swiperApi'
import { BIG, SMALL } from 'lib/transform'

export default function useAnimatedSlide() {
    const animationStateRef = useRef({
        isAnimating: false,
        pendingCompletion: false,
        rafId: null as number | null,
    })

    // Get animation state from store
    const {
        animatedPanelInsert,
        completeAnimatedInsert,
        animatedPanelRemove,
        completeAnimatedRemove,
    } = useStore.getState().panels

    // Memoize the completion handler
    const handleAnimationComplete = useCallback(
        (isInsert: boolean) => {
            if (animationStateRef.current.pendingCompletion) return

            animationStateRef.current.pendingCompletion = true

            // Use RAF to batch the completion
            requestAnimationFrame(() => {
                if (isInsert) {
                    completeAnimatedInsert()
                } else {
                    completeAnimatedRemove()
                }
                animationStateRef.current.isAnimating = false
                animationStateRef.current.pendingCompletion = false
            })
        },
        [completeAnimatedInsert, completeAnimatedRemove],
    )

    // Calculate transform styles based on animation state
    const getTransformStyle = useCallback(
        (index: number) => {
            if (!animatedPanelInsert && !animatedPanelRemove) return {}

            // Start animation tracking
            if (!animationStateRef.current.isAnimating) {
                animationStateRef.current.isAnimating = true
            }

            const centerIndex = getCenterIndex()
            const rightTranslate = 'translateX(100%)'
            const leftTranslate = 'translateX(-100%)'
            let scale
            let translate

            const hideStyles = {
                display: 'none',
            }

            if (animatedPanelInsert) {
                if (animatedPanelInsert.direction === 'right') {
                    if (index < animatedPanelInsert.index) return {}
                    translate = rightTranslate
                    if (index === centerIndex - 1) scale = BIG
                    else if (index === centerIndex) scale = SMALL
                } else if (animatedPanelInsert.direction === 'left') {
                    if (index >= animatedPanelInsert.index) return {}
                    translate = leftTranslate
                    if (index === centerIndex + 1) scale = BIG
                    else if (index === centerIndex) scale = SMALL
                }
            }

            if (animatedPanelRemove) {
                if (animatedPanelRemove.direction === 'right') {
                    if (index > animatedPanelRemove.index) return {}
                    else if (index === animatedPanelRemove.index) return hideStyles
                    else {
                        translate = rightTranslate
                        if (index === centerIndex - 1) scale = BIG
                        else if (index === centerIndex) scale = SMALL
                    }
                } else if (animatedPanelRemove.direction === 'left') {
                    if (index < animatedPanelRemove.index) return {}
                    else if (index === animatedPanelRemove.index) return hideStyles
                    else {
                        translate = leftTranslate
                        if (index === centerIndex + 1) scale = BIG
                        else if (index === centerIndex) scale = SMALL
                    }
                }
            }

            return {
                transform: scale ? `${translate} scale(${scale})` : translate,
                transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                willChange: 'transform',
            }
        },
        [animatedPanelInsert, animatedPanelRemove],
    )

    // Listen for transition end to complete animation
    useEffect(() => {
        if (!animatedPanelInsert && !animatedPanelRemove) {
            // Reset animation state when no animation is running
            animationStateRef.current.isAnimating = false
            animationStateRef.current.pendingCompletion = false
            return
        }

        const handleTransitionEnd = (e: TransitionEvent) => {
            if (e.propertyName === 'transform') {
                // Schedule completion for next frame to avoid forced reflow
                animationStateRef.current.rafId = requestAnimationFrame(() => {
                    // Batch reads
                    const isAnimating = animationStateRef.current.isAnimating
                    const isPending = animationStateRef.current.pendingCompletion

                    if (!isAnimating || isPending) return

                    // Batch writes
                    handleAnimationComplete(!!animatedPanelInsert)
                })
            }
        }

        document.addEventListener('transitionend', handleTransitionEnd)
        return () => {
            document.removeEventListener('transitionend', handleTransitionEnd)
            if (animationStateRef.current.rafId) {
                cancelAnimationFrame(animationStateRef.current.rafId)
            }
        }
    }, [animatedPanelInsert, animatedPanelRemove, handleAnimationComplete])

    return {
        getTransformStyle,
    }
}
